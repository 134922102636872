import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Avatar, Box, Typography, Tooltip, Link } from "@mui/material";
import {
    DataGrid,
    getGridNumericOperators,
    getGridStringOperators,
} from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import PercentageChange from "./percentagechange";
import axios from 'axios';
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { BACKEND_URL } from '../config.js';

const OperatorsTable = () => {
    const [data, setData] = useState([]); // State to hold the fetched data
    const [openTooltip, setOpenTooltip] = useState(null); // State to handle tooltip visibility
    //const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .MuiTooltip-tooltip`]: {
            backgroundColor: theme.palette.mode === 'dark' ? '#1a233b' : '#e0e0e0', // Solid background color
            color: theme.palette.mode === 'dark' ? '#fff' : '#000', // Adjust text color for better visibility
            border: `1px solid ${theme.palette.mode === 'dark' ? '#1a75ff' : '#333'}`, // Optional: border for better visibility
        },
    });

    useEffect(() => {
        axios.get(`${BACKEND_URL}/operators-general`)
            .then(response => {
                console.log(response.data); // Debugging line
                setData(response.data); // Assuming the backend returns the data array directly
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    /*
    const handleNavigate = useCallback((row) => {
        navigate(`/operator/${row.operator_address}`, { state: { logo: row.logo_url, name: row.operator_name } });
    }, [navigate]);
    */

    const xIcon = theme.palette.mode === 'dark' ? '../../assets/xImage.jpeg' : '../../assets/xImage.jpeg';
    const websiteIcon = theme.palette.mode === 'dark' ? '../../assets/websiteImage_with_circle.png' : '../../assets/websiteImage.png';
    const eigenlayerIcon = theme.palette.mode === 'dark' ? '../../assets/eigenlayerImage_with_circle.png' : '../../assets/eigenlayerImage.png';

    const numericFilterOperators = getGridNumericOperators().map((operator) => ({
        ...operator,
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }

            const valueAsNumber = parseFloat(filterItem.value);

            return ({ value }) => {
                if (value == null) {
                    return false;
                }
                const valueAsNumber2 = parseFloat(value);
                switch (filterItem.operatorValue) {
                    case '=':
                        return valueAsNumber2 === valueAsNumber;
                    case '!=':
                        return valueAsNumber2 !== valueAsNumber;
                    case '>':
                        return valueAsNumber2 > valueAsNumber;
                    case '>=':
                        return valueAsNumber2 >= valueAsNumber;
                    case '<':
                        return valueAsNumber2 < valueAsNumber;
                    case '<=':
                        return valueAsNumber2 <= valueAsNumber;
                    default:
                        return false;
                }
            };
        }
    }));


    const handleOpenTooltip = useCallback((index) => {
        setOpenTooltip(openTooltip === index ? null : index);
    }, [openTooltip]);

    const columns = useMemo(() => [
        {
            field: "logo_url",
            headerName: "Operator",
            flex: 0.6,
            minWidth: 80,
            valueGetter: (params) => params.row.operator_name,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            filterOperators: getGridStringOperators(),
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    //cursor: 'pointer' // Changes the cursor to pointer on hover
                }} /*onClick={() => handleNavigate(params.row)}*/>
                    <Tooltip title={params.row.operator_name}>
                        <Avatar src={params.row.logo_url} alt={params.row.operator_name} sx={{ width: 30, height: 30, marginRight: 1 }} />
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: "avs_logos",
            headerName: "AVS",
            flex: 1.5,
            minWidth: 160,
            sortable: false,
            filterOperators: getGridStringOperators(),
            renderCell: (params) => {
                const avsLogos = params.value; // This is already an array
                const avsNames = params.row.avs_names; // This is also an array
                const displayAvs = avsLogos.slice(0, 4);
                const remainingAvs = avsLogos.slice(4);

                return (
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap', // Allow items to wrap onto the next line
                        gap: 0.5, // Space between logos
                        alignItems: 'center',
                    }}>
                        {displayAvs.map((logo, index) => (
                            <Tooltip title={avsNames[index]} key={index}>
                                <Avatar src={logo} alt={avsNames[index]} sx={{ width: 21, height: 21 }} />
                            </Tooltip>
                        ))}
                        {remainingAvs.length > 0 && (
                            <CustomTooltip
                                title={
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: 200 }}>
                                        {remainingAvs.map((logo, index) => (
                                            <Tooltip title={avsNames[4 + index]} key={index}>
                                                <Avatar src={logo} alt={avsNames[4 + index]} sx={{ width: 21, height: 21, margin: 0.5 }} />
                                            </Tooltip>
                                        ))}
                                    </Box>
                                }
                                placement="bottom"
                                arrow
                                open={openTooltip === params.row.operator_address} // Control tooltip visibility
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleOpenTooltip(params.row.operator_address)}>
                                    <Avatar sx={{ width: 21, height: 21 }}>
                                        +{remainingAvs.length}
                                    </Avatar>
                                </Box>
                            </CustomTooltip>
                        )}
                    </Box>
                );
            },
        },
        {
            field: "distinct_avs_count",
            headerName: "Num of AVSs",
            flex: 0.5,
            minWidth: 70,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "tvl_eth",
            headerName: "TVL (ETH)",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {Number(params.value).toLocaleString()}
                </Typography>
            ),
        },
        {
            field: "operator_share_pct_eth",
            headerName: "ETH Market Share (%)",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {params.value}%
                </Typography>
            ),
        },
        {
            headerName: "ETH TVL Change 24h (%)",
            field: "tvl_change_pct_eth_24h",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
            ),
        },
        {
            headerName: "ETH TVL Change 7d (%)",
            field: "tvl_change_pct_eth_7d",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
            ),
        },
        {
            headerName: "ETH TVL Change 30d (%)",
            field: "tvl_change_pct_eth_30d",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
            ),
        },
        {
            field: "tvl_eigen",
            headerName: "TVL (EIGEN)",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {Number(params.value).toLocaleString()}
                </Typography>
            ),
        },
        {
            field: "operator_share_pct_eigen",
            headerName: "EIGEN Market Share (%)",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {params.value}%
                </Typography>
            ),
        },
        {
            headerName: "EIGEN TVL Change 24h (%)",
            field: "tvl_change_pct_eigen_24h",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
            ),
        },
        {
            headerName: "EIGEN TVL Change 7d (%)",
            field: "tvl_change_pct_eigen_7d",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
            ),
        },
        {
            headerName: "EIGEN TVL Change 30d (%)",
            field: "tvl_change_pct_eigen_30d",
            flex: 0.9,
            minWidth: 100,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
            ),
        },
        /*
        {
            headerName: "TVL Change (ETH)",
            field: "tvl_change_pct_eth",
            flex: 2.5,
            renderHeader: () => (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <Typography variant="subtitle2">TVL Change (ETH)</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'left' }}>24h</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'left' }}>7d</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'left' }}>30d</Typography>
                    </Box>
                </Box>
            ),
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <PercentageChange value={params.row.tvl_change_pct_eth_24h || 0} sx={{ flex: 1, textAlign: 'left' }} />
                    <PercentageChange value={params.row.tvl_change_pct_eth_7d || 0} sx={{ flex: 1, textAlign: 'left' }} />
                    <PercentageChange value={params.row.tvl_change_pct_eth_30d || 0} sx={{ flex: 1, textAlign: 'left' }} />
                </Box>
            ),
        },
        {
            headerName: "TVL Change (Eigen)",
            field: "tvl_change_pct_eigen",
            flex: 2.5,
            renderHeader: () => (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <Typography variant="subtitle2">TVL Change (Eigen)</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'left' }}>24h</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>7d</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'right' }}>30d</Typography>
                    </Box>
                </Box>
            ),
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <PercentageChange value={params.row.tvl_change_pct_eigen_24h || 0} sx={{ flex: 1, textAlign: 'left' }} />
                    <PercentageChange value={params.row.tvl_change_pct_eigen_7d || 0} sx={{ flex: 1, textAlign: 'center' }} />
                    <PercentageChange value={params.row.tvl_change_pct_eigen_30d || 0} sx={{ flex: 1, textAlign: 'right' }} />
                </Box>
            ),
        },
        */
        {
            field: "operator_links",
            headerName: "Links",
            flex: 1,
            minWidth: 120,
            sortable: false,
            filterOperators: getGridStringOperators(),
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link href={params.row.operator_twitter_link} target="_blank" rel="noopener" sx={{ marginRight: 1 }}>
                        <Avatar src={xIcon} sx={{ width: 24, height: 24 }} />
                    </Link>
                    <Link href={params.row.operator_website_link} target="_blank" rel="noopener" sx={{ marginRight: 1 }}>
                        <Avatar src={websiteIcon} sx={{ width: 24, height: 24 }} />
                    </Link>
                    <Link href={params.row.operator_eigenlayer_link} target="_blank" rel="noopener">
                        <Avatar src={eigenlayerIcon} sx={{ width: 24, height: 24 }} />
                    </Link>
                </Box>
            ),
        },
    ], [xIcon, websiteIcon, eigenlayerIcon, openTooltip, handleOpenTooltip, numericFilterOperators]);

    return (
        <Box
            height="75vh"
            sx={{
                overflowX: 'auto',
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: `0.3px dotted ${colors.grey[400]}`, // Add border bottom to each cell,
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[600],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.primary[600],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.primary[600],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    overflow: "visible",
                    whiteSpace: "normal",
                    lineHeight: "normal",
                    display: 'block', // Ensure block display to allow height adjustment
                },
            }}
        >
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={(row) => row.operator_address} // Specifying custom getRowId function
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'tvl_eth', sort: 'desc' }],
                    },
                }}
                //headerHeight={70} // Adjust this value as needed
            />
        </Box>
    );
};

export default OperatorsTable;
