// src/components/AvsTable.jsx

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Avatar, Box, Typography, Tooltip, useTheme, Link } from '@mui/material';
import {DataGrid, getGridNumericOperators, getGridStringOperators} from '@mui/x-data-grid';
import { tokens } from '../theme';
import axios from 'axios';
import { BACKEND_URL} from '../config';
import PercentageChange from '../components/percentagechange';
import {useNavigate} from "react-router-dom";
const AvsTable = () => {
    const [data, setData] = useState([]); // State to hold the fetched data
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${BACKEND_URL}/avs-general`)
            .then(response => {
                setData(response.data); // Assuming the backend returns the data array directly
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleNavigate = useCallback((row) => {
        navigate(`/avs/${row.avs}`, {
            state: {
                avsName: row.avs_name,
                avsLogo: row.logo_url
            }
        });
    }, [navigate]);

    const xIcon = theme.palette.mode === 'dark' ? '../../assets/xImage.jpeg' : '../../assets/xImage.jpeg';
    const websiteIcon = theme.palette.mode === 'dark' ? '../../assets/websiteImage_with_circle.png' : '../../assets/websiteImage.png';
    const eigenlayerIcon = theme.palette.mode === 'dark' ? '../../assets/eigenlayerImage_with_circle.png' : '../../assets/eigenlayerImage.png';

    const numericFilterOperators = getGridNumericOperators().map((operator) => ({
        ...operator,
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }

            const valueAsNumber = parseFloat(filterItem.value);

            return ({ value }) => {
                if (value == null) {
                    return false;
                }
                const valueAsNumber2 = parseFloat(value);
                switch (filterItem.operatorValue) {
                    case '=':
                        return valueAsNumber2 === valueAsNumber;
                    case '!=':
                        return valueAsNumber2 !== valueAsNumber;
                    case '>':
                        return valueAsNumber2 > valueAsNumber;
                    case '>=':
                        return valueAsNumber2 >= valueAsNumber;
                    case '<':
                        return valueAsNumber2 < valueAsNumber;
                    case '<=':
                        return valueAsNumber2 <= valueAsNumber;
                    default:
                        return false;
                }
            };
        }
    }));

    const columns = useMemo(() => [
        {
            field: "avs",
            headerName: "AVS",
            flex: 0.6,
            minWidth: 80, // Added minWidth
            valueGetter: (params) => params.row.avs_name,
            filterOperators: getGridStringOperators(),
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer', // Changes the cursor to pointer on hover
                    }}
                    onClick={() => handleNavigate(params.row)}
                >
                    <Tooltip title={params.row.avs_name}>
                        <Avatar
                            src={params.row.logo_url}
                            alt={params.row.avs_name}
                            className="avatar"
                            sx={{
                                width: 30,
                                height: 30,
                                marginRight: 2,
                                border: `2px solid transparent`,
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    borderColor: theme.palette.primary.main,
                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.7)',
                                    transform: 'scale(1.2)',
                                },
                            }}
                        />
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: "num_operators",
            headerName: "Num Operators",
            flex: 0.6,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {Number(params.value).toLocaleString()}
                </Typography>
            ),
        },
        {
            field: "eth_restaked",
            headerName: "ETH Restaked",
            flex: 1,
            minWidth: 120, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {Number(params.value).toLocaleString()}
                </Typography>
            ),
        },
        {
            headerName: "ETH Restaked Change 24h (%)",
            field: "eth_restaked_change_24h_pct",
            flex: 1,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Box sx={{ fontSize: '0.8rem' }}>
                    <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
                </Box>
            ),
        },
        {
            headerName: "ETH Restaked Change 7d (%)",
            field: "eth_restaked_change_7d_pct",
            flex: 1,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Box sx={{ fontSize: '0.8rem' }}>
                    <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
                </Box>
            ),
        },
        {
            headerName: "ETH Restaked Change 30d (%)",
            field: "eth_restaked_change_30d_pct",
            flex: 1,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Box sx={{ fontSize: '0.8rem' }}>
                    <PercentageChange value={params.value || 0} fontSize="0.9rem"/>
                </Box>
            ),
        },
        {
            headerName: "Top Operator Dominance (%)",
            field: "single_operator_dominance_pct",
            flex: 1,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {params.value || 'N/A'}%
                </Typography>
            ),
        },
        {
            headerName: "Top 3 Operator Dominance (%)",
            field: "top_3_operator_dominance_pct",
            flex: 1,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {params.value || 'N/A'}%
                </Typography>
            ),
        },
        {
            headerName: "Top 5 Operator Dominance (%)",
            field: "top_5_operator_dominance_pct",
            flex: 1,
            minWidth: 100, // Added minWidth
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {params.value || 'N/A'}%
                </Typography>
            ),
        },
        /*
        {
            field: "eth_restaked_change_pct",
            headerName: "ETH Restaked Change (%)",
            flex: 1.5,
            renderHeader: () => (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="subtitle2">ETH Restaked Change (%)</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>24h</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>7d</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>30d</Typography>
                    </Box>
                </Box>
            ),
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <PercentageChange value={params.row.eth_restaked_change_24h_pct || 0} sx={{ flex: 1, textAlign: 'center' }} />
                    <PercentageChange value={params.row.eth_restaked_change_7d_pct || 0} sx={{ flex: 1, textAlign: 'center' }} />
                    <PercentageChange value={params.row.eth_restaked_change_30d_pct || 0} sx={{ flex: 1, textAlign: 'center' }} />
                </Box>
            ),
        },
        {
            field: "operator_dominance",
            headerName: "Operator Dominance (%)",
            flex: 1.5,
            renderHeader: () => (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="subtitle2">Operator Dominance (%)</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>Single</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>Top 3</Typography>
                        <Typography variant="caption" sx={{ flex: 1, textAlign: 'center' }}>Top 5</Typography>
                    </Box>
                </Box>
            ),
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>{params.row.single_operator_dominance_pct || 'N/A'}%</Typography>
                    <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>{params.row.top_3_operator_dominance_pct || 'N/A'}%</Typography>
                    <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>{params.row.top_5_operator_dominance_pct || 'N/A'}%</Typography>
                </Box>
            ),
        },
        */
        {
            field: "avs_links",
            headerName: "Links",
            flex: 1,
            minWidth: 120, // Added minWidth
            sortable: false,
            filterOperators: getGridStringOperators(),
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link href={params.row.avs_twitter_link} target="_blank" rel="noopener" sx={{ marginRight: 1 }}>
                        <Avatar src={xIcon} sx={{ width: 24, height: 24 }} />
                    </Link>
                    <Link href={params.row.avs_website_link} target="_blank" rel="noopener" sx={{ marginRight: 1 }}>
                        <Avatar src={websiteIcon} sx={{ width: 24, height: 24 }} />
                    </Link>
                    <Link href={params.row.avs_eigenlayer_link} target="_blank" rel="noopener">
                        <Avatar src={eigenlayerIcon} sx={{ width: 24, height: 24 }} />
                    </Link>
                </Box>
            ),
        },
    ] , [xIcon, websiteIcon, eigenlayerIcon, handleNavigate, numericFilterOperators, theme.palette.primary.main]); // Dependencies for the memoization;

    return (
        <Box
            height="75vh"
            sx={{
                overflowX: 'auto',
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: `0.3px dotted ${colors.grey[400]}`, // Add border bottom to each cell
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.primary[600],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    overflow: "visible",
                    whiteSpace: "normal",
                    lineHeight: "normal",
                    display: 'block', // Ensure block display to allow height adjustment
                },
            }}
        >
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={(row) => row.avs} // Using AVS as the row ID
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'eth_restaked', sort: 'desc' }],
                    },
                }}
                //headerHeight={70} // Adjust this value as needed
            />
        </Box>
    );
};

export default AvsTable;
