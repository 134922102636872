import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, IconButton, Tab, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Plot from 'react-plotly.js';
import Header from "../../components/Header";
import InfoIcon from '@mui/icons-material/Info';
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import OperatorsTable from "../../components/OperatorsTable";
import AvsTable from "../../components/AvsTable";
import NumberChange from "../../components/NumberChange";
import { BACKEND_URL } from '../../config';
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.7rem',
  },
}));

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const loadingGif = '../../assets/loadingGifLight.gif';
  const logo = theme.palette.mode === 'dark'
      ? '../../assets/eigenbeatLogoSideTextDark.png'
      : '../../assets/eigenbeatLogoSideTextLight.png';

  const [totalTvl, setTotalTvl] = useState('Loading...'); // Initialize state for total TVL
  const [tvlChanges, setTvlChanges] = useState({
    tvlChange24h: 'Loading...',
    tvlChange7d: 'Loading...',
    tvlChange30d: 'Loading...',
    tvlChange24hPct: 'Loading...',
    tvlChange7dPct: 'Loading...',
    tvlChange30dPct: 'Loading...',
  });
  const [tabIndex, setValueTabIndex] = useState(0);
  const [percentageTabIndex, setPercentageTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValueTabIndex(newValue);
  };

  const handlePercentageTabChange = (event, newValue) => {
    setPercentageTabIndex(newValue);
  };

  const getNetflowValue = () => {
    switch (tabIndex) {
      case 0:
        return memoizedTvlChanges.tvlChange24h;
      case 1:
        return memoizedTvlChanges.tvlChange7d;
      case 2:
        return memoizedTvlChanges.tvlChange30d;
      default:
        return 0;
    }
  };

  const getNetflowPctValue = () => {
    switch (percentageTabIndex) {
      case 0:
        return memoizedTvlChanges.tvlChange24hPct;
      case 1:
        return memoizedTvlChanges.tvlChange7dPct;
      case 2:
        return memoizedTvlChanges.tvlChange30dPct;
      default:
        return 0;
    }
  };

  const [numberOfAvss, setNumberOfAvss] = useState('Loading...');
  const [numberOfActiveOperators, setNumberOfActiveOperators] = useState('Loading...');
  const [nativeRestakingRatio, setNativeRestakingRatio] = useState('Loading...');
  const [idleEthRatio, setIdleEthRatio] = useState('Loading...');
  const [dailyInfOutEthTransactionsChartData, setDailyInfOutEthTransactionsChartData] = useState({ data: [], layout: {} });
  const [dailyNetflowEthTransactionsChartData, setDailyNetflowEthTransactionsChartData] = useState({ data: [], layout: {} });
  const [tvlOverTimeChartData, setTvlOverTimeChartData] = useState({ data: [], layout: {} });

  useEffect(() => {
    const fetchTVL = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/total-tvl`);
        const data = response.data;
        if (data && data.length > 0) {
          setTotalTvl(Number(data[0].total_tvl).toLocaleString());
        } else {
          setTotalTvl('No data');
        }
      } catch (error) {
        console.error('Failed to fetch TVL data:', error);
        setTotalTvl('Failed to load data');
      }
    };

    const fetchTvlChanges = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/tvl-changes`);
        const data = response.data;
        if (data && data.length > 0) {
          setTvlChanges({
            tvlChange24h: Number(data[0].tvl_change_24h),
            tvlChange7d: Number(data[0].tvl_change_7d),
            tvlChange30d: Number(data[0].tvl_change_30d),
            tvlChange24hPct: Number(data[0].tvl_change_24h_pct),
            tvlChange7dPct: Number(data[0].tvl_change_7d_pct),
            tvlChange30dPct: Number(data[0].tvl_change_30d_pct),
          });
        } else {
          setTvlChanges({
            tvlChange24h: 'No data',
            tvlChange7d: 'No data',
            tvlChange30d: 'No data',
            tvlChange24hPct: 'No data',
            tvlChange7dPct: 'No data',
            tvlChange30dPct: 'No data',
          });
        }
      } catch (error) {
        console.error('Failed to fetch TVL change data:', error);
        setTvlChanges({
          tvlChange24h: 'Failed to load data',
          tvlChange7d: 'Failed to load data',
          tvlChange30d: 'Failed to load data',
          tvlChange24hPct: 'Failed to load data',
          tvlChange7dPct: 'Failed to load data',
          tvlChange30dPct: 'Failed to load data',
        });
      }
    };

    const fetchNumberOfAvss = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/number-of-avss`);
        const data = response.data;
        if (data && data.length > 0) {
          setNumberOfAvss(Number(data[0].number_of_avss).toLocaleString());
        } else {
          setNumberOfAvss('No data');
        }
      } catch (error) {
        console.error('Failed to fetch number of AVSs:', error);
        setNumberOfAvss('Failed to load data');
      }
    };

    const fetchNumberOfActiveOperators = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/number-of-active-operators`);
        const data = response.data;
        if (data && data.length > 0) {
          setNumberOfActiveOperators(Number(data[0].number_of_active_operators).toLocaleString());
        } else {
          setNumberOfActiveOperators('No data');
        }
      } catch (error) {
        console.error('Failed to fetch number of active operators:', error);
        setNumberOfActiveOperators('Failed to load data');
      }
    };

    const fetchNativeRestakingRatio = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/native-restaking-ratio`);
        const data = response.data;
        if (data && data.length > 0) {
          setNativeRestakingRatio(Number(data[0].native_retaking_ratio_percentage));
        } else {
          setNativeRestakingRatio('No data');
        }
      } catch (error) {
        console.error('Failed to fetch native restaking ratio:', error);
        setNativeRestakingRatio('Failed to load data');
      }
    };

    const fetchIdleEthRatio = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/idle-eth-ratio`);
        const data = response.data;
        if (data && data.length > 0) {
          setIdleEthRatio(Number(data[0].idle_eth_ratio_percentage));
        } else {
          setIdleEthRatio('No data');
        }
      } catch (error) {
        console.error('Failed to fetch idle ETH ratio:', error);
        setIdleEthRatio('Failed to load data');
      }
    };

    const fetchDailyInfOutEthTransactionsChart = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/daily-inf/out-eth-transactions-chart`);
        setDailyInfOutEthTransactionsChartData(response.data);
      } catch (error) {
        console.error('Error fetching Ethereum transactions chart data:', error);
        setDailyInfOutEthTransactionsChartData({ data: [], layout: {} });
      }
    };

    const fetchDailyNetflowEthTransactionsChart = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/daily-netflow-eth-transactions-chart`);
        setDailyNetflowEthTransactionsChartData(response.data);
      } catch (error) {
        console.error('Error fetching Ethereum transactions chart data:', error);
        setDailyNetflowEthTransactionsChartData({ data: [], layout: {} });
      }
    };

    const fetchTvlOverTimeChart = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/tvl-over-time-chart`);
        setTvlOverTimeChartData(response.data);
      } catch (error) {
        console.error('Error fetching Ethereum transactions chart data:', error);
        setTvlOverTimeChartData({ data: [], layout_light: {}, layout_dark: {} });
      }
    };

    fetchTVL();
    fetchTvlChanges();
    fetchNumberOfAvss();
    fetchNumberOfActiveOperators();
    fetchNativeRestakingRatio();
    fetchIdleEthRatio();
    fetchDailyInfOutEthTransactionsChart();
    fetchDailyNetflowEthTransactionsChart();
    fetchTvlOverTimeChart();
  }, []);  // The empty array ensures these effects only run once after the component mounts

  const memoizedTotalTvl = useMemo(() => totalTvl, [totalTvl]);
  const memoizedTvlChanges = useMemo(() => tvlChanges, [tvlChanges]);
  const memoizedNumberOfAvss = useMemo(() => numberOfAvss, [numberOfAvss]);
  const memoizedNumberOfActiveOperators = useMemo(() => numberOfActiveOperators, [numberOfActiveOperators]);
  const memoizedNativeRestakingRatio = useMemo(() => nativeRestakingRatio, [nativeRestakingRatio]);
  const memoizedIdleEthRatio = useMemo(() => idleEthRatio, [idleEthRatio]);
  const memoizedDailyInfOutEthTransactionsChart = useMemo(() => {
    const currentLayout = theme.palette.mode === 'dark' ? dailyInfOutEthTransactionsChartData.layout_dark : dailyInfOutEthTransactionsChartData.layout_light;

    return (
        <Plot
            data={dailyInfOutEthTransactionsChartData.data}
            layout={currentLayout}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
        />
    );
  }, [dailyInfOutEthTransactionsChartData, theme.palette.mode]);

  const memoizedDailyNetflowEthTransactionsChart = useMemo(() => {
    const currentLayout = theme.palette.mode === 'dark' ? dailyNetflowEthTransactionsChartData.layout_dark : dailyNetflowEthTransactionsChartData.layout_light;

    return (
        <Plot
            data={dailyNetflowEthTransactionsChartData.data}
            layout={currentLayout}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
        />
    );
  }, [dailyNetflowEthTransactionsChartData, theme.palette.mode]);

  const memoizedTvlOverTimeChart = useMemo(() => {
    const currentLayout = theme.palette.mode === 'dark' ? tvlOverTimeChartData.layout_dark : tvlOverTimeChartData.layout_light;

    return (
        <Plot
            data={tvlOverTimeChartData.data}
            layout={currentLayout}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
        />
    );
  }, [tvlOverTimeChartData, theme.palette.mode]);

  return (
      <Box m="20px">
        {/* HEADER */}
        <Box
            display="flex"
            alignItems="center"
            sx={{ height: '135px'}} // Adjust the height and margin as needed
        >
          <Avatar src={logo} alt="Eigenbeat Logo" sx={{ width: '100%', height: '100%', maxWidth: '400px', maxHeight: '400px' }} />
        </Box>

        {/* GRID & CHARTS */}
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100px"
            gap="20px"
        >
          {/* ROW 1 */}
          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align items to the start of the box
              justifyContent="center"
              p={3} // Adding some padding
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                  variant="subtitle1"
                  sx={{fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem'}}
              >
                TVL (ETH)
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              {memoizedTotalTvl === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <Typography
                      variant="h4"
                      component="h1"
                      sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)'}}
                  >
                    {memoizedTotalTvl}
                  </Typography>
              )}
            </Box>
          </Box>

          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align items to the start of the box
              justifyContent="center"
              p={3} // Adding some padding
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                  variant="subtitle1"
                  sx={{fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem'}}
              >
                Number of AVSs
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              {memoizedNumberOfAvss === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <Typography
                      variant="h4"
                      component="h1"
                      sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)'}}
                  >
                    {memoizedNumberOfAvss}
                  </Typography>
              )}
            </Box>
          </Box>

          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align items to the start of the box
              justifyContent="center"
              p={3} // Adding some padding
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                  variant="subtitle1"
                  sx={{fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem'}}
              >
                Number of Active Operators
              </Typography>
              <CustomTooltip title="An active operator is defined as one who supports at least one AVS and has at least 100 ETH or 10,000 EIGEN delegated" placement="top">
                <IconButton
                    aria-label="info"
                    sx={{padding: 0, fontSize: '0.8rem' }}
                >
                  <InfoIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
              <Box display="flex" alignItems="center">
              {memoizedNumberOfActiveOperators === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <Typography
                      variant="h4"
                      component="h1"
                      sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)'}}
                  >
                    {memoizedNumberOfActiveOperators}
                  </Typography>
              )}
              </Box>
          </Box>

          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={3}
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center">
                <Typography
                    variant="subtitle1"
                    sx={{ fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem' }}
                >
                  Netflow (ETH)
                </Typography>
              </Box>
              <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="Netflow time period tabs"
                  textColor="inherit"
                  indicatorColor="primary"
                  sx={{
                    '.MuiTabs-flexContainer': {
                      justifyContent: 'flex-end',
                    },
                    minHeight: 'unset',
                    '& .MuiTab-root': {
                      minWidth: 'unset',
                      minHeight: 'unset',
                      padding: '6px 12px',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                    },
                  }}
              >
                <Tab label="24h" />
                <Tab label="7d" />
                <Tab label="30d" />
              </Tabs>
            </Box>
            <Box display="flex" alignItems="center">
              {getNetflowValue() === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <NumberChange
                      value={getNetflowValue()}
                      isSmallScreen={isSmallScreen}
                  />
              )}
            </Box>
          </Box>

          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={3}
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center">
                <Typography
                    variant="subtitle1"
                    sx={{ fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem' }}
                >
                  ETH TVL Growth (%)
                </Typography>
              </Box>
              <Tabs
                  value={percentageTabIndex}
                  onChange={handlePercentageTabChange}
                  aria-label="Netflow percentage time period tabs"
                  textColor="inherit"
                  indicatorColor="primary"
                  sx={{
                    '.MuiTabs-flexContainer': {
                      justifyContent: 'flex-end',
                    },
                    minHeight: 'unset',
                    '& .MuiTab-root': {
                      minWidth: 'unset',
                      minHeight: 'unset',
                      padding: '6px 12px',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                    },
                  }}
              >
                <Tab label="24h" />
                <Tab label="7d" />
                <Tab label="30d" />
              </Tabs>
            </Box>
            <Box display="flex" alignItems="center">
              {getNetflowPctValue() === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <NumberChange
                      value={getNetflowPctValue()}
                      isPercentage={true}
                      isSmallScreen={isSmallScreen}
                  />
              )}
            </Box>
          </Box>

          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align items to the start of the box
              justifyContent="center"
              p={3} // Adding some padding
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                  variant="subtitle1"
                  sx={{fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem'}}
              >
                Native Restaking Percentage
              </Typography>
              <CustomTooltip title="Percentage of natively restaked ETH out of all ETH restaked" placement="top">
                <IconButton
                    aria-label="info"
                    sx={{padding: 0, fontSize: '0.8rem' }}
                >
                  <InfoIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
            <Box display="flex" alignItems="center">
              {memoizedNativeRestakingRatio === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <Typography
                      variant="h4"
                      component="h1"
                      sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)'}}
                  >
                    {memoizedNativeRestakingRatio}%
                  </Typography>
              )}
            </Box>
          </Box>

          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align items to the start of the box
              justifyContent="center"
              p={3} // Adding some padding
              sx={{ height: '100%' }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                  variant="subtitle1"
                  sx={{fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem'}}
              >
                Idle ETH Percentage
              </Typography>
              <CustomTooltip title="Percentage of all restaked ETH that is not delegated to any operator" placement="top">
                <IconButton
                    aria-label="info"
                    sx={{padding: 0, fontSize: '0.8rem' }}
                >
                  <InfoIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
            <Box display="flex" alignItems="center">
              {memoizedIdleEthRatio === 'Loading...' ? (
                  <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
              ) : (
                  <Typography
                      variant="h4"
                      component="h1"
                      sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)'}}
                  >
                    {memoizedIdleEthRatio}%
                  </Typography>
              )}
            </Box>
          </Box>

          {/*
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        */}
          {/* ROW 2 */}
          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 6"}
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
          >
            {memoizedDailyInfOutEthTransactionsChart}

          </Box>
          <Box
              gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 6"}
              gridRow="span 4"
              backgroundColor={colors.primary[400]}
              overflow="auto"
          >
            {memoizedDailyNetflowEthTransactionsChart}
          </Box>

          {/* ROW 3 */}

          <Box
              gridColumn="span 12"
              gridRow="span 5"
              //p="30px"
              justifyContent="center"
              alignItems="center"
              //backgroundColor={colors.primary[400]}
          >
            {memoizedTvlOverTimeChart}

          </Box>

          {/* Operators and AVS Tables */}
          <Box gridColumn="span 12" gridRow="span 6" display="flex" flexDirection="column" justifyContent="stretch" marginTop="1rem">
            <Header title="AVSs"/>
            <AvsTable sx={{ flexGrow: 1 }} />
          </Box>

          <Box gridColumn="span 12" gridRow="span 6" display="flex" flexDirection="column" justifyContent="stretch" marginTop="1rem">
            <Header title="Operators"/>
            <OperatorsTable sx={{ flexGrow: 1 }} />
          </Box>
        </Box>
      </Box>
  );
};

export default Dashboard;
