import React, {} from 'react';
import { Avatar, Box, useTheme} from '@mui/material';
import Header from '../../components/Header';
import AvsTable from "../../components/AvsTable";
import {Link as RouterLink} from "react-router-dom";

const Avs = () => {
    const theme = useTheme();
    const logo = theme.palette.mode === 'dark'
        ? '../../assets/eigenbeatLogoSideTextDark.png'
        : '../../assets/eigenbeatLogoSideTextLight.png';
    return (
        <Box m="20px">
            <Box
                display="flex"
                alignItems="center"
                sx={{ height: '135px' }} // Adjust the height and margin as needed
            >
                <RouterLink to="/" style={{ display: 'inline-block', cursor: 'pointer' }}>
                    <Avatar src={logo} alt="Eigenbeat Logo" sx={{ width: '100%', height: '100%', maxWidth: '400px', maxHeight: '400px'}} />
                </RouterLink>
            </Box>
            <Header title="AVSs" /* subtitle="List of AVS and their metrics" */ />
            <AvsTable />
        </Box>
    );
};

export default Avs;
