import {useCallback, useMemo, useState} from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import HomePage from "./scenes/homePage";
import Operators from "./scenes/operators";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avs from "./scenes/avs";
import AvsDetails from "./scenes/avsDetails";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const contentStyle = useMemo(() => ({
    marginLeft: !isSmallScreen && isSidebarOpen ? '250px' : '0',
    width: !isSmallScreen && isSidebarOpen ? 'calc(100% - 250px)' : '100%',
    paddingLeft: !isSmallScreen && isSidebarOpen ? '20px' : '0',
    marginTop: '4rem', // Adjust according to the height of your Topbar
  }), [isSmallScreen, isSidebarOpen]);

  const toggleSidebar = useCallback(() => setIsSidebarOpen(prev => !prev), []);

  return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <main className="content" style={contentStyle}>
              <Topbar toggleSidebar={toggleSidebar} />
              <Routes>
                <Route path="/" element={<HomePage />} />
                {/*<Route path="/lrt" element={<Lrt />} /> */}
                <Route path="/avs" element={<Avs />} />
                <Route path="/operators" element={<Operators />} />
                {/*<Route path="/operator/:id" element={<OperatorDetails />} />*/}
                <Route path="/avs/:id" element={<AvsDetails />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default App;
