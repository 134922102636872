import { useState } from "react";
import {Box, IconButton, Typography, useTheme, Drawer, MenuItem} from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Link to={to} style={{ textDecoration: 'none', color: 'inherit', width: '100%', display: 'flex', alignItems: 'center' }}>
            <MenuItem
                active={selected === title}
                style={{ color: colors.grey[100] }}
                onClick={() => setSelected(title)}
                icon={icon}
            >
                <Typography>{title}</Typography>
            </MenuItem>
        </Link>
    );
};

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("");

    const logoSrc = theme.palette.mode === 'dark'
        ? '../../assets/eigenbeatTextLogoDark.png'
        : '../../assets/eigenbeatTextLogoLight.png';

    return (
        <Drawer
            anchor="left"
            open={isSidebarOpen}
            onClose={toggleSidebar}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '250px',
                    background: `${colors.primary[400]} !important`,
                    color: colors.grey[100],
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    '& .pro-inner-item': {
                        padding: '5px 35px 5px 20px !important',
                    },
                    '& .pro-inner-item:hover': {
                        color: '#868dfb !important',
                    },
                    '& .pro-menu-item.active': {
                        color: '#6870fa !important',
                    },
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                >
                    <IconButton onClick={toggleSidebar}>
                        <MenuOutlinedIcon />
                    </IconButton>
                </Box>

                <Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Link to="/" style={{ display: 'inline-block', cursor: 'pointer' }}>
                            <img
                                alt="profile-user"
                                width="250px"
                                height="250px"
                                src={logoSrc}
                                style={{ cursor: 'pointer', borderRadius: '50%' }}
                            />
                        </Link>
                    </Box>
                </Box>

                <Box paddingLeft="10%">
                    <Item
                        title="Home"
                        to="/"
                        icon={<HomeOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    {/*
                    <Typography
                        variant="h6"
                        color={colors.grey[300]}
                        sx={{ m: '15px 0 5px 20px' }}
                    >
                        Data
                    </Typography>
                    */}
                    {/*
                    <Item
                        title="LRT"
                        to="/lrt"
                        icon={<PeopleOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    */}
                    <Item
                        title="AVS"
                        to="/avs"
                        icon={<ContactsOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    <Item
                        title="Operators"
                        to="/operators"
                        icon={<ReceiptOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                    />
                </Box>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
