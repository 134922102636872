import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const NumberChange = ({ value, isPercentage = false, isSmallScreen }) => {
    const isPositive = value >= 0;
    const color = isPositive ? 'green' : 'red';
    const ArrowIcon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', color }}>
            <ArrowIcon fontSize="large" />
            <Typography
                variant="h4"
                component="h1"
                sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}
            >
                {Math.abs(value).toLocaleString()}{isPercentage && '%'}
            </Typography>
        </Box>
    );
};

export default NumberChange;

