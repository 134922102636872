import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PercentageChange = ({ value, fontSize }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const isPositive = value >= 0;
    const color = isPositive
        ? (isDarkMode ? '#00FF00' : 'green')
        : (isDarkMode ? '#FF0000' : 'red'); // Shiny red for dark mode

    const ArrowIcon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', color, fontSize }}>
            <ArrowIcon fontSize="small" />
            <Typography variant="body2" sx={{ fontSize }}>{Math.abs(value)}%</Typography>
        </Box>
    );
};

export default PercentageChange;
