import React, { useEffect, useState, useMemo } from 'react';
import {Box, Typography, Tooltip, useTheme, IconButton, tooltipClasses, Avatar} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom'; // Import Link from react-router-dom
import { tokens } from "../../theme";
import Header from "../../components/Header";
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import OperatorsTable from "../../components/OperatorsTable";
import { BACKEND_URL } from '../../config';
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

const loadingGif = '../../assets/loadingGifLight.gif';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '0.7rem',
    },
}));

const Operators = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const [numberOfActiveOperators, setNumberOfActiveOperators] = useState('Loading...');
    const [totalEthDelegated, setTotalEthDelegated] = useState('Loading...');
    const [totalEigenDelegated, setTotalEigenDelegated] = useState('Loading...');
    const [idleEthRatio, setIdleEthRatio] = useState('Loading...');

    const logo = theme.palette.mode === 'dark'
        ? '../../assets/eigenbeatLogoSideTextDark.png'
        : '../../assets/eigenbeatLogoSideTextLight.png';

    useEffect(() => {
        const fetchNumberOfActiveOperators = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/number-of-active-operators`);
                const data = response.data;
                if (data && data.length > 0) {
                    setNumberOfActiveOperators(Number(data[0].number_of_active_operators).toLocaleString());
                } else {
                    setNumberOfActiveOperators('No data');
                }
            } catch (error) {
                console.error('Failed to fetch number of active operators:', error);
                setNumberOfActiveOperators('Failed to load data');
            }
        };

        const fetchTotalEthDelegated = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/total-eth-delegated`);
                const data = response.data;
                if (data && data.length > 0) {
                    setTotalEthDelegated(Number(data[0].total_delegated_eth).toLocaleString());
                } else {
                    setTotalEthDelegated('No data');
                }
            } catch (error) {
                console.error('Failed to fetch total ETH delegated:', error);
                setTotalEthDelegated('Failed to load data');
            }
        };

        const fetchTotalEigenDelegated = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/total-eigen-delegated`);
                const data = response.data;
                if (data && data.length > 0) {
                    setTotalEigenDelegated(Number(data[0].total_eigen_delegated).toLocaleString());
                } else {
                    setTotalEigenDelegated('No data');
                }
            } catch (error) {
                console.error('Failed to fetch total EIGEN delegated:', error);
                setTotalEigenDelegated('Failed to load data');
            }
        };

        const fetchIdleEthRatio = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/idle-eth-ratio`);
                const data = response.data;
                if (data && data.length > 0) {
                    setIdleEthRatio(Number(data[0].idle_eth_ratio_percentage));
                } else {
                    setIdleEthRatio('No data');
                }
            } catch (error) {
                console.error('Failed to fetch idle ETH ratio:', error);
                setIdleEthRatio('Failed to load data');
            }
        };

        fetchNumberOfActiveOperators();
        fetchTotalEthDelegated();
        fetchTotalEigenDelegated();
        fetchIdleEthRatio();
    }, []);

    const memoizedNumberOfActiveOperators = useMemo(() => numberOfActiveOperators, [numberOfActiveOperators]);
    const memoizedTotalEthDelegated = useMemo(() => totalEthDelegated, [totalEthDelegated]);
    const memoizedTotalEigenDelegated = useMemo(() => totalEigenDelegated, [totalEigenDelegated]);
    const memoizedIdleEthRatio = useMemo(() => idleEthRatio, [idleEthRatio]);

    return (
        <Box m="20px">
            <Box
                display="flex"
                alignItems="center"
                sx={{ height: '135px'}} // Adjust the height and margin as needed
            >
                <RouterLink to="/" style={{ display: 'inline-block', cursor: 'pointer' }}>
                    <Avatar src={logo} alt="Eigenbeat Logo" sx={{ width: '100%', height: '100%', maxWidth: '400px', maxHeight: '400px'}} />
                </RouterLink>
            </Box>
            <Header title="OPERATORS" />
            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="100px"
                gap="20px"
            >
                {/* NEW BLOCKS */}
                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start" // Align items to the start of the box
                    justifyContent="center"
                    p={3} // Adding some padding
                    sx={{ height: '100%' }}
                >
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="subtitle1"
                            sx={{ fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem' }}
                        >
                            Number of Active Operators
                        </Typography>
                        <CustomTooltip title="An active operator is defined as one who supports at least one AVS and has at least 100 ETH or 10,000 EIGEN delegated" placement="top">
                            <IconButton
                                aria-label="info"
                                sx={{ padding: 0, fontSize: '0.8rem' }}
                            >
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {memoizedNumberOfActiveOperators === 'Loading...' ? (
                            <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
                        ) : (
                            <Typography
                                variant="h4"
                                component="h1"
                                sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}
                            >
                                {memoizedNumberOfActiveOperators}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start" // Align items to the start of the box
                    justifyContent="center"
                    p={3} // Adding some padding
                    sx={{ height: '100%' }}
                >
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="subtitle1"
                            sx={{ fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem' }}
                        >
                            Total ETH Delegated
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {memoizedTotalEthDelegated === 'Loading...' ? (
                            <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
                        ) : (
                            <Typography
                                variant="h4"
                                component="h1"
                                sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}
                            >
                                {memoizedTotalEthDelegated}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start" // Align items to the start of the box
                    justifyContent="center"
                    p={3} // Adding some padding
                    sx={{ height: '100%' }}
                >
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="subtitle1"
                            sx={{ fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem' }}
                        >
                            Total EIGEN Delegated
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {memoizedTotalEigenDelegated === 'Loading...' ? (
                            <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
                        ) : (
                            <Typography
                                variant="h4"
                                component="h1"
                                sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}
                            >
                                {memoizedTotalEigenDelegated}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 3"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start" // Align items to the start of the box
                    justifyContent="center"
                    p={3} // Adding some padding
                    sx={{ height: '100%' }}
                >
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="subtitle1"
                            sx={{ fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)', marginRight: '0.5rem' }}
                        >
                            Idle ETH Percentage
                        </Typography>
                        <CustomTooltip title="Percentage of all restaked ETH that is not delegated to any operator" placement="top">
                            <IconButton
                                aria-label="info"
                                sx={{ padding: 0, fontSize: '0.8rem' }}
                            >
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {memoizedIdleEthRatio === 'Loading...' ? (
                            <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
                        ) : (
                            <Typography
                                variant="h4"
                                component="h1"
                                sx={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}
                            >
                                {memoizedIdleEthRatio}%
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>

            <Box mt="20px">
                <OperatorsTable />
            </Box>
        </Box>
    );
};

export default Operators;
