import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Topbar = ({ toggleSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: colors.primary[400],
                zIndex: 1200, // Ensure the topbar is above other elements
            }}
        >
            {/* Menu Icon for toggling sidebar */}
            <IconButton onClick={toggleSidebar}>
                <MenuOutlinedIcon />
            </IconButton>
            {/* ICONS */}
            <Box display="flex" ml="auto">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <LightModeOutlinedIcon />
                    ) : (
                        <DarkModeOutlinedIcon />
                    )}
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;
