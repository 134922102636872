// src/scenes/AvsDetails.jsx

import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Tooltip,
    IconButton,
    useTheme,
    Avatar,
    LinearProgress,
    linearProgressClasses
} from '@mui/material';
import { tokens } from '../../theme';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { BACKEND_URL } from '../../config';
import NumberChange from '../../components/NumberChange';
import Plot from 'react-plotly.js';
import { DataGrid, getGridNumericOperators, getGridStringOperators } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const loadingGif = '../../assets/loadingGifLight.gif';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#E0E0E0' : '#E0E0E0',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#1a90ff',
    },
}));

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .MuiTooltip-tooltip`]: {
        fontSize: '0.7rem', // Adjust this value for tooltip text size
    },
});

function LinearProgressWithLabel(props) {
    const progressPercentage = props.value;
    const { avsName, comparedAvsName } = props;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
            <Box sx={{ width: '100%', position: 'relative' }}>
                <BorderLinearProgress variant="determinate" value={progressPercentage} />
                <CustomTooltip title={`${progressPercentage}% of ${avsName}'s restaked ETH is also securing ${comparedAvsName}`} placement="top">
                    <Box sx={{ width: `${progressPercentage}%`, height: '100%', position: 'absolute', top: 0, left: 0 }} />
                </CustomTooltip>
            </Box>
            <Box sx={{ minWidth: 35, ml: "0.9rem" }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(progressPercentage)}%`}</Typography>
            </Box>
        </Box>
    );
}

const AvsDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const { avsName, avsLogo } = location.state || {};
    const [avsInfo, setAvsInfo] = useState(null);
    const [ethRestakedChartData, setEthRestakedChartData] = useState({ data: [], layout: {} });
    const [numOperatorsChartData, setNumOperatorsChartData] = useState({ data: [], layout: {} });
    const [overlapData, setOverlapData] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    useEffect(() => {
        axios.get(`${BACKEND_URL}/avs-info/${id}`)
            .then(response => {
                setAvsInfo(response.data[0]); // Assuming the backend returns an array
            })
            .catch(error => {
                console.error('Error fetching AVS info:', error);
            });

        axios.get(`${BACKEND_URL}/avs-over-time-chart/${id}`)
            .then(response => {
                setEthRestakedChartData(response.data.eth_restaked_chart_data);
                setNumOperatorsChartData(response.data.num_operators_chart_data);
            })
            .catch(error => {
                console.error('Error fetching AVS over time chart data:', error);
            });

        axios.get(`${BACKEND_URL}/avs-restake-overlap/${id}`)
            .then(response => {
                setOverlapData(response.data);
            })
            .catch(error => {
                console.error('Error fetching AVS restake overlap data:', error);
            });
    }, [id]);

    const memoizedEthRestakedChart = useMemo(() => {
        const currentLayout = theme.palette.mode === 'dark' ? ethRestakedChartData.layout_dark : ethRestakedChartData.layout_light;

        return (
            <Plot
                data={ethRestakedChartData.data}
                layout={currentLayout}
                useResizeHandler={true}
                style={{ width: "100%", height: "100%" }}
            />
        );
    }, [ethRestakedChartData, theme.palette.mode]);

    const memoizedNumOperatorsChart = useMemo(() => {
        const currentLayout = theme.palette.mode === 'dark' ? numOperatorsChartData.layout_dark : numOperatorsChartData.layout_light;

        return (
            <Plot
                data={numOperatorsChartData.data}
                layout={currentLayout}
                useResizeHandler={true}
                style={{ width: "100%", height: "100%" }}
            />
        );
    }, [numOperatorsChartData, theme.palette.mode]);

    const numericFilterOperators = getGridNumericOperators().map((operator) => ({
        ...operator,
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }

            const valueAsNumber = parseFloat(filterItem.value);

            return ({ value }) => {
                if (value == null) {
                    return false;
                }
                const valueAsNumber2 = parseFloat(value);
                switch (filterItem.operatorValue) {
                    case '=':
                        return valueAsNumber2 === valueAsNumber;
                    case '!=':
                        return valueAsNumber2 !== valueAsNumber;
                    case '>':
                        return valueAsNumber2 > valueAsNumber;
                    case '>=':
                        return valueAsNumber2 >= valueAsNumber;
                    case '<':
                        return valueAsNumber2 < valueAsNumber;
                    case '<=':
                        return valueAsNumber2 <= valueAsNumber;
                    default:
                        return false;
                }
            };
        }
    }));

    const columns = useMemo(() => [
        {
            field: "compared_avs",
            headerName: "Compared AVS",
            flex: 1,
            minWidth: 200,
            filterOperators: getGridStringOperators(),
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    <Avatar
                        src={params.row.compared_avs_logo}
                        alt={params.row.compared_avs_name}
                        sx={{
                            width: 30,
                            height: 30,
                            marginRight: 2,
                        }}
                    />
                    <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>{params.row.compared_avs_name}</Typography>
                </Box>
            ),
        },
        {
            field: "overlap_percentage",
            headerName: "Overlap Percentage",
            flex: 1,
            minWidth: 200,
            filterOperators: numericFilterOperators,
            renderCell: (params) => (
                <LinearProgressWithLabel value={params.value} avsName={avsName} comparedAvsName={params.row.compared_avs_name} />
            ),
        },
    ], [avsName, numericFilterOperators]);

    if (!avsInfo) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <img src={loadingGif} alt="Loading..." style={{ width: '4%' }} />
            </Box>
        );
    }

    return (
        <Box m="20px">
            <Box display="flex" alignItems="center" mb={3}>
                <Box display="flex" alignItems="center" mb={3}>
                    {avsLogo && (
                        <Avatar
                            src={avsLogo}
                            alt={avsName}
                            sx={{
                                width: 60,
                                height: 60,
                                marginRight: 2,
                                border: `2px solid ${colors.primary[100]}`,
                                backgroundColor: colors.primary[400],
                            }}
                        />
                    )}
                    <Typography variant="h2" sx={{ fontWeight: 'bold' }}>{avsName}</Typography>
                </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="100px" gap="20px">
                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Total ETH Restaked
                        </Typography>
                    </Box>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}>
                        {Number(avsInfo.eth_restaked).toLocaleString()}
                    </Typography>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Number of Operators
                        </Typography>
                    </Box>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}>
                        {Number(avsInfo.num_operators).toLocaleString()}
                    </Typography>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            ETH Restaked for Single AVS
                        </Typography>
                        <CustomTooltip title="The total amount of ETH restaked that is securing only this AVS and not any other AVS" placement="top">
                            <IconButton aria-label="info" sx={{ padding: 0, fontSize: '0.8rem' }}>
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}>
                        {Number(avsInfo.dedicated_eth_restaked).toLocaleString()}
                    </Typography>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Total ETH Restaked Growth % 24h
                        </Typography>
                    </Box>
                    <NumberChange value={avsInfo.eth_restaked_change_24h_pct} isPercentage={true} isSmallScreen={isSmallScreen} />
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Total ETH Restaked Growth % 7d
                        </Typography>
                    </Box>
                    <NumberChange value={avsInfo.eth_restaked_change_7d_pct} isPercentage={true} isSmallScreen={isSmallScreen} />
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Total ETH Restaked Growth % 30d
                        </Typography>
                    </Box>
                    <NumberChange value={avsInfo.eth_restaked_change_30d_pct} isPercentage={true} isSmallScreen={isSmallScreen} />
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Top Operator Dominance
                        </Typography>
                        <CustomTooltip title="This measures the dominance of the leading operator in terms of ETH contribution to the AVS's security." placement="top">
                            <IconButton aria-label="info" sx={{ padding: 0, fontSize: '0.8rem' }}>
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}>
                        {avsInfo.single_operator_dominance_pct}%
                    </Typography>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Top 3 Operators Dominance
                        </Typography>
                        <CustomTooltip title="This measures the combined dominance of the leading three operators in terms of ETH contribution to the AVS's security." placement="top">
                            <IconButton aria-label="info" sx={{ padding: 0, fontSize: '0.8rem' }}>
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}>
                        {avsInfo.top_3_operator_dominance_pct}%
                    </Typography>
                </Box>

                <Box
                    gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 4"}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    p={3}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{ marginRight: '0.5rem', fontSize: isSmallScreen ? '1rem' : 'clamp(0.8rem, 2vw, 1rem)' }}>
                            Top 5 Operators Dominance
                        </Typography>
                        <CustomTooltip title="This measures the combined dominance of the leading five operators in terms of ETH contribution to the AVS's security" placement="top">
                            <IconButton aria-label="info" sx={{ padding: 0, fontSize: '0.8rem' }}>
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '2.5rem' : 'clamp(1rem, 3vw, 2.5rem)' }}>
                        {avsInfo.top_5_operator_dominance_pct}%
                    </Typography>
                </Box>

                <Box gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 6"} gridRow="span 4" backgroundColor={colors.primary[400]}>
                    {memoizedEthRestakedChart}
                </Box>

                <Box gridColumn={isSmallScreen ? "span 12" : isMediumScreen ? "span 12" : "span 6"} gridRow="span 4" backgroundColor={colors.primary[400]}>
                    {memoizedNumOperatorsChart}
                </Box>


                <Box gridColumn="span 12" gridRow="span 4" marginTop="2rem">
                    <Box display="flex" alignItems="center">
                        <Typography variant="h2" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', mb: "1rem" }}>
                            Shared Security with Other AVSs
                        </Typography>
                        <CustomTooltip title="The percentage of one AVS's restaked ETH that is also being used to secure other AVSs. This measures the extent of shared security among multiple AVSs" placement="top">
                            <IconButton aria-label="info" sx={{ padding: 0, fontSize: '0.8rem', ml: 1, mb: "0.8rem" }}>
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Box>

                    <Box
                        height="75vh"
                        sx={{
                            overflowX: 'auto',
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: `0.3px dotted ${colors.grey[400]}`,
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.primary[600],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.primary[600],
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                overflow: "visible",
                                whiteSpace: "normal",
                                lineHeight: "normal",
                                display: 'block', // Ensure block display to allow height adjustment
                            },
                        }}
                    >
                        <DataGrid
                            rows={overlapData}
                            columns={columns}
                            getRowId={(row) => row.compared_avs}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'overlap_percentage', sort: 'desc' }],
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AvsDetails;
